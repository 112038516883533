<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="
            handleSubmit(
              dataReceived.title === 'Add New Itinerary'
                ? addNewItinerary
                : updateExistingItinerary
            )
          "
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">{{ dataReceived.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Itinerary Name"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Itinerary Name"
                      v-model="name"
                      class="form-control"
                      placeholder="Itinerary Name"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-map-marker-path"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <validation-provider name="Active Event" mode="lazy">
                    <v-checkbox
                      label="Active Itinerary"
                      hide-details
                      class="mt-n2"
                      v-model="isActive"
                    ></v-checkbox>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="$emit('disableDialog', false), resetForm()"
            >
              Close
            </v-btn>
            <v-btn
              v-if="dataReceived.title == 'Add New Itinerary'"
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Add
            </v-btn>
            <v-btn
              v-else
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import ItineraryService from "../../services/ItineraryService.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  watch: {
    dataReceived: {
      handler: function () {
        if (this.dataReceived.title === "Update Itinerary") {
          this.id = this.dataReceived.itineraryId;
          this.name = this.dataReceived.name;
          this.isActive = this.dataReceived.isActive;
        }
      },
      deep: true,
    },
  },
  mounted() {},
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dataReceived: {
      type: Object,
    },
  },
  data: () => ({
    itineraryId: 0,
    name: "",
    isActive: true,
    actionBy: 1,
    loading: false,
  }),

  methods: {
    async addNewItinerary() {
      try {
        this.loading = true;
        const response = await ItineraryService.addItinerary({
          id: this.id,
          name: this.name,
          isActive: this.isActive,
          actionBy: this.actionBy,
        });

        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Itinerary is added Successfully",
              type: "success",
            },
            { root: true }
          );
          this.resetForm();
          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.$emit("reloadItinerary");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async updateExistingItinerary() {
      try {
        this.loading = true;
        const response = await ItineraryService.updateItinerary({
          id: this.id,
          name: this.name,
          isActive: this.isActive,
          actionBy: this.actionBy,
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Itinerary is updated Successfully",
              type: "success",
            },
            { root: true }
          );
          this.resetForm();
          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.$emit("reloadItinerary");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    resetForm() {
      this.$refs.modalObserver.reset();
      // this.$refs.modalForm.reset();
      this.name = "";
      this.isActive = true;
    },
  },
};
</script>
