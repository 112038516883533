var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{ref:"modalObserver",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('v-form',{ref:"modalForm",staticClass:"newClass",on:{"submit":function($event){$event.preventDefault();return handleSubmit(
              _vm.dataReceived.title == 'Add New User'
                ? _vm.addNewUser
                : _vm.updateExistingUser
            )}}},[_c('v-card-title',{staticClass:"card-title-dialog"},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.dataReceived.title))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"User Name","rules":{
                      required: true,
                      min: 6,
                      regex: /^[a-zA-Z][a-zA-Z0-9.,$;].+$/,
                    },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"label":"User Name","placeholder":"User Name","required":"","outlined":"","hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-account","disabled":_vm.dataReceived.title === 'Update User' || _vm.loading},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":{ required: true, email: true },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Email","placeholder":"Email","required":"","outlined":"","hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-at","disabled":_vm.loading},model:{value:(_vm.emailId),callback:function ($$v) {_vm.emailId=$$v},expression:"emailId"}})]}}],null,true)})],1),(_vm.dataReceived.title == 'Add New User')?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","rules":{
                      required: true,
                      min: 8,
                      regex:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                    },"vid":"confirm","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Password","title":"Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character.","placeholder":"Password","required":"","outlined":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-lock-check","type":_vm.showPassword ? 'text' : 'password',"disabled":_vm.loading},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1):_vm._e(),(_vm.dataReceived.title == 'Add New User')?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":{
                      required: true,
                      confirmed: _vm.password,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"hint":"Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character.","label":"Confirm Password","placeholder":"Confirm Password","required":"","outlined":"","append-icon":_vm.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-lock-check","type":_vm.showConfirmPassword ? 'text' : 'password',"disabled":_vm.loading},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Address","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Address","placeholder":"Address","required":"","outlined":"","hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-map-marker","disabled":_vm.loading},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":{
                      regex: /^(\d{3}-)\d{3}-\d{4}$/,
                    },"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Phone Number","placeholder":"Phone Number","required":"","outlined":"","hide-details":"","error-messages":errors,"prepend-icon":"mdi-phone","disabled":_vm.loading,"value":_vm.acceptNumber(_vm.phoneNumber),"hint":"The Phone format is XXX-XXX-XXXX","maxlength":"12","persistent-hint":""},on:{"keypress":_vm.onlyNumbers,"input":function($event){_vm.phoneNumber = $event.replaceAll('-', '')}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"User Type","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.roles,"item-text":"roleName","item-value":"roleId","label":"User Type","outlined":"","hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-account-box-multiple-outline","disabled":(!_vm.roleAdmin &&
                          !_vm.dataReceived.title === 'Add New User') ||
                        _vm.loading},model:{value:(_vm.rolename),callback:function ($$v) {_vm.rolename=$$v},expression:"rolename"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Active Event","mode":"lazy"}},[_c('v-checkbox',{staticClass:"mt-n2",attrs:{"label":"Active User","hide-details":"","disabled":_vm.loading},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.resetForm(), _vm.$emit('disableDialog', false)}}},[_vm._v(" Close ")]),(_vm.dataReceived.title == 'Add New User')?_c('v-btn',{attrs:{"color":"add-btn","type":"submit","text":"","disabled":_vm.loading,"loading":_vm.loading}},[_vm._v(" Add ")]):_c('v-btn',{attrs:{"color":"add-btn","type":"submit","text":"","disabled":_vm.loading,"loading":_vm.loading}},[_vm._v(" Update ")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }