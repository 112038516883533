<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="
            handleSubmit(
              dataReceived.title == 'Add New User'
                ? addNewUser
                : updateExistingUser
            )
          "
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">{{ dataReceived.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="User Name"
                    :rules="{
                      required: true,
                      min: 6,
                      regex: /^[a-zA-Z][a-zA-Z0-9.,$;].+$/,
                    }"
                    mode="lazy"
                  >
                    <v-text-field
                      label="User Name"
                      v-model="userName"
                      class="form-control"
                      placeholder="User Name"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-account"
                      :disabled="
                        dataReceived.title === 'Update User' || loading
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    :rules="{ required: true, email: true }"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Email"
                      v-model="emailId"
                      class="form-control"
                      placeholder="Email"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-at"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" v-if="dataReceived.title == 'Add New User'">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    :rules="{
                      required: true,
                      min: 8,
                      regex:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                    }"
                    vid="confirm"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Password"
                      v-model="password"
                      title="Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character."
                      class="form-control"
                      placeholder="Password"
                      required
                      outlined
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-lock-check"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" v-if="dataReceived.title == 'Add New User'">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirm Password"
                    :rules="{
                      required: true,
                      confirmed: password,
                    }"
                  >
                    <v-text-field
                      hint="Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character."
                      label="Confirm Password"
                      v-model="confirmPassword"
                      class="form-control"
                      placeholder="Confirm Password"
                      required
                      outlined
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-lock-check"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Address"
                      v-model="address"
                      class="form-control"
                      placeholder="Address"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-map-marker"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone Number"
                    :rules="{
                      regex: /^(\d{3}-)\d{3}-\d{4}$/,
                    }"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Phone Number"
                      class="form-control"
                      placeholder="Phone Number"
                      required
                      outlined
                      hide-details=""
                      :error-messages="errors"
                      prepend-icon="mdi-phone"
                      :disabled="loading"
                      :value="acceptNumber(phoneNumber)"
                      @keypress="onlyNumbers"
                      @input="phoneNumber = $event.replaceAll('-', '')"
                      hint="The Phone format is XXX-XXX-XXXX"
                      maxlength="12"
                      persistent-hint
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="User Type"
                    mode="lazy"
                  >
                    <v-select
                      v-model="rolename"
                      :items="roles"
                      item-text="roleName"
                      item-value="roleId"
                      label="User Type"
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-account-box-multiple-outline"
                      :disabled="
                        (!roleAdmin &&
                          !dataReceived.title === 'Add New User') ||
                        loading
                      "
                    ></v-select>
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <validation-provider name="Active Event" mode="lazy">
                    <v-checkbox
                      label="Active User"
                      hide-details
                      class="mt-n2"
                      v-model="isActive"
                      :disabled="loading"
                    ></v-checkbox>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="resetForm(), $emit('disableDialog', false)"
            >
              Close
            </v-btn>
            <v-btn
              v-if="dataReceived.title == 'Add New User'"
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Add
            </v-btn>
            <v-btn
              v-else
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import UserService from "../../services/UserService.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  watch: {
    dataReceived: {
      handler: function () {
        if (this.dataReceived.title === "Update User") {
          this.id = this.dataReceived.id;
          this.address = this.dataReceived.address;
          this.emailId = this.dataReceived.email;
          this.isActive = this.dataReceived.isActive;
          // this.password = this.dataReceived.password;
          // this.confirmPassword = this.dataReceived.password;
          this.phoneNumber = this.dataReceived.phoneNumber;
          this.userName = this.dataReceived.userName;
          this.rolename = this.dataReceived.roleId || 1;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    const response = await UserService.getRolestype();
    this.roles = response.data.data;
    // const roleId = this.$store.state.auth.userState.roleId;
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dataReceived: {
      type: Object,
    },
  },
  computed: {
    roleAdmin() {
      return (
        this.dataReceived.userName !== this.$store.state.auth.userState.userName
      );
    },
  },
  data: () => ({
    id: 0,
    userName: "",
    password: "",
    emailId: "",
    phoneNumber: "",
    address: "",
    roleId: 1,
    isActive: true,
    loading: false,
    showPassword: false,
    showConfirmPassword: false,
    confirmPassword: "",
    roles: [],
    rolename: 3,
  }),

  methods: {
    async addNewUser() {
      try {
        this.loading = true;
        const response = await UserService.addUser({
          id: 0,
          userName: this.userName,
          password: this.password,
          email: this.emailId,
          phoneNumber: this.phoneNumber || "",
          address: this.address || "",
          roleId: this.rolename,
          isActive: true,
        });

        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
          this.resetForm();
          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }

        this.$emit("reloadUser");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async updateExistingUser() {
      try {
        this.loading = true;
        if (!this.roleAdmin) {
          const response = await UserService.updateUser({
            id: this.id,
            userName: this.userName,
            email: this.emailId,
            phoneNumber: this.phoneNumber,
            address: this.address,
            isActive: this.isActive,
            roleId: 0,
          });
          if (response.data.errorMessage == "") {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.data,
                type: "success",
              },
              { root: true }
            );
            this.resetForm();
            this.$emit("disableDialog", false);
          } else {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.errorMessage,
                type: "error",
              },
              { root: true }
            );
          }
        } else {
          const response = await UserService.updateUserRole({
            id: this.id,
            userName: this.userName,
            password: this.password,
            email: this.emailId,
            phoneNumber: this.phoneNumber,
            address: this.address,
            isActive: this.isActive,
            roleId: this.rolename,
          });
          if (response.data.errorMessage == "") {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.data,
                type: "success",
              },
              { root: true }
            );
            this.resetForm();
            this.$emit("disableDialog", false);
          } else {
            this.$store.dispatch(
              "notifications/setNotification",
              {
                text: response.data.errorMessage,
                type: "error",
              },
              { root: true }
            );
          }
        }

        this.$emit("reloadUser");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    acceptNumber(value) {
      if (value !== null && value !== "" && value !== 0) {
        const x = String(value)
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2]
          ? `${x[1]}`
          : `${x[1]}-${x[2]}${x[3] ? `${"-" + x[3]}` : ""}`;
      }
    },
    onlyNumbers(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    resetForm() {
      this.$refs.modalObserver.reset();
      // this.$refs.modalForm.reset();
      this.userName = "";
      this.password = "";
      this.emailId = "";
      this.phoneNumber = "";
      this.address = "";
      this.roleId = 1;
      this.isActive = true;
    },
  },
};
</script>
