<template>
  <master-layout>
    <div class="mb-3 text-right">
      <v-btn
        class="ml-3 add-btn"
        dark
        @click="addOrUpdateUser('add')"
        :disabled="!accessEnableAdd"
      >
        <v-icon>mdi-plus</v-icon> Add User
      </v-btn>
    </div>
    <v-card>
      <v-row class="ma-0">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            User List
            <!-- <v-spacer></v-spacer> -->
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            max-width="400px"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        item-key="id"
        :headers="registerHeader"
        :items="registrationList"
        class="elevation-1 mytable"
        :loading="loading"
        must-sort
        :search="search"
      >
        <template v-slot:[`header.name`]="{ header }">
          {{ header.text.toUpperCase() }}
        </template>
        <template v-slot:[`item.phoneNumber`]="{ item }">
          {{
            item.phoneNumber
              .replace(/\D+/g, "")
              .replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "$1-$2-$3")
          }}
        </template>

        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon style="color: #f0ce6a" v-if="item.isActive"
            >mdi-check-bold</v-icon
          >
          <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                v-bind="attrs"
                v-on="on"
                :disabled="!accessEnableUpdate"
                @click="addOrUpdateUser('update', item)"
              >
                <v-icon color="primary">mdi-pencil</v-icon></v-btn
              >
            </template>
            Edit User
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <!-- <progress-bar :show="loading" />
    <v-simple-table fixed-header height="600px" class="d-none d-sm-block">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">User Name</th>
            <th class="text-center">Email</th>
            <th class="text-center">Contact</th>
            <th class="text-center">Address</th>

            <th class="text-center">Role Name</th>
            
            <th class="text-center">Active</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr align="center" v-for="item in UserDetail" :key="item.id">
            <td>{{ item.userName }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phoneNumber }}</td>
            <td>{{ item.address }}</td>

            <td>{{ item.roleName }}</td>
            <td>
              <v-icon style="color: #f0ce6a" v-if="item.isActive"
                >mdi-check-bold</v-icon
              >
              <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
            </td>
            <td>
              <v-btn
                v-if="item.roleName !== 'Agent'"
                text
                class="edit-pancil"
                @click="addOrUpdateUser('update', item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
    <!-- mobile view -->
    <!-- <v-card
      class="d-sm-none mobile_card mb-3"
      elevation="1"
      outlined
      v-for="item in UserDetail"
      :key="item.id"
    >
      <div class="d-flex align-center card-head">
        <div>
          <strong>{{ item.userName }}</strong>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="pr-0 m-edit"
          @click="addOrUpdateUser('update', item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <div class="d-flex pt-3 crad-body-content">
        <div>
          <ul class="list">
            <li class="list-weblink">
              <label>Email</label>
              <span>{{ item.email }}</span>
            </li>
            <li class="list-address-info">
              <label>Role Name</label>
              <span>{{ item.roleName }}</span>
            </li>
            <li class="list-call-info">
              <label>Mobile</label>
              <span>{{ item.phoneNumber }}</span>
            </li>
            <li class="list-active-info">
              <label>Active</label>
              <span>
                <v-icon style="color: #f0ce6a" v-if="item.isActive"
                  >mdi-check-bold</v-icon
                >
                <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </v-card> -->

    <UserModal
      :dialog="dialog"
      :dataReceived="updateUserValue"
      @reloadUser="getUserList()"
      @disableDialog="(dialog = $event), (updateUserValue = {})"
    />
  </master-layout>
</template>
<script>
import UserService from "../services/UserService.js";
import UserModal from "../components/Modals/UserModal.vue";
import { actionEnable } from "../utils/helpers";
export default {
  components: {
    UserModal,
  },
  name: "UserManagement",
  data: () => ({
    name: "",
    address: "",
    phone: "",
    UserDetail: [],
    dialog: false,
    updateUserValue: {},
    loading: false,
    accessEnable: [],
    registerHeader: [
      { text: "User Name", value: "userName", width: 125 },
      { text: "Email", value: "email", width: 125 },
      { text: "Contact", value: "phoneNumber", width: 125 },
      { text: "Address", value: "address", width: 125 },
      { text: "Role Name", value: "roleName", width: 125 },
      { text: "Active", value: "isActive", width: 125, sortable: false },
      { text: "Actions", value: "action", width: 125, sortable: false },
    ],
    registrationList: [],
    search: "",
  }),
  computed: {
    accessEnableAdd() {
      return actionEnable("User", "Add", 1);
    },
    accessEnableUpdate() {
      return actionEnable("User", "UpdateProfile", 1);
    },
  },
  async mounted() {
    this.getUserList();
  },
  methods: {
    async getUserList() {
      try {
        this.loading = true;
        const response = await UserService.userList();
        this.registrationList = response.data.data;
        this.UserDetail = response.data.data;
        if (response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    async getUserDetails() {
      try {
        const { data } = await UserService.getUserDetail({});
        this.UserDetail = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    addOrUpdateUser(value, item) {
      if (value == "add") {
        this.dialog = true;
        this.updateUserValue = { title: "Add New User" };
      } else {
        this.dialog = true;
        this.updateUserValue = { ...item, title: "Update User" };
      }
    },
  },
};
</script>
